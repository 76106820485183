@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

a.product_brief__button--add-to-bag {
  width: inherit;
}
/* footer css for ipad portrait */
@media only screen and (max-width: 1024px) and (min-width: 768px) and (orientation: portrait) {
  .footer-legal .menu li.last {
    margin-top: -57px !important;
    margin-right: 126px !important;
  }
}
/* set the font for MPP page */

.mpp__header,
.headline--tertiary,
.header__headline span {
  font-family: 'Optima Display Roman' !important;
}
